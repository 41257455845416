import { Switch } from "@mui/material";
import colors from "../../../../../../common/colors";
import styled from "@emotion/styled";

export const ToggleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledSwitch = styled(Switch)`
  && {
    transform: scale(0.8);
  }
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${colors.lavender};
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.lavender};
  }
  & .MuiSwitch-switchBase {
    color: ${colors.grey};
  }
  & .MuiSwitch-track {
    background-color: ${colors.grey};
  }
`;
