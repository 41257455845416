import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader";
import Heading1 from "../../common/Heading1";
import Scheduler from "./schedule/Scheduler";
import colors from "../../common/colors";

const SchedulePage = () => {
  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Umfrage</Heading1>
      </AreaSectionHeader>
      <AreaSection
        content={sectionContent.ScheduleSection}
        backLinkTo={undefined}
        isCustom={false}
        areaName={undefined}
        areaId={undefined}
        updateQuestionList={undefined}
      >
        <Scheduler />
      </AreaSection>
    </>
  );
};

export default SchedulePage;

const sectionContent = {
  ScheduleSection: {
    id: "schedule",
    title: "Deine Pulse-Check-Mails",
    infoText:
      "Hier kannst Du den Versand der Fragen individuell nach Deinen Bedürfnissen verwalten.",
    hoverText:
      "Anhand dieser Planung bestimmt Du den Zeitpunkt, an dem alle Mitarbeiter*innen die Fragen erhalten sollen. Die Änderungen sind ab dem nächsten Tag gültig.",
  },
};
