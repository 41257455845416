import Dropdown from "../../../../../../common/Dropdown";
import colors from "../../../../../../common/colors";
import { useState } from "react";

interface QuantitiyDropdownprops {
  day: number;
  quantity: number | undefined;
  updateQuantity: (weekDay: number, newQuantity: number) => void;
  isActive: boolean | undefined;
}

export const QuantityDropdown = (props: QuantitiyDropdownprops) => {
  const [selectedOption, setSelectedOption] = useState<number>(
    props.quantity ? props.quantity : 1
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target);
    setSelectedOption(Number(event.target.value));
    props.updateQuantity(props.day, parseInt(event.target.value));
  };

  const undefinedCheck = (value: boolean | undefined) => {
    return value ? value : false;
  };

  return (
    <Dropdown
      key={props.day}
      menuItems={menuItems}
      label={undefined}
      multiple={false}
      value={selectedOption}
      onChange={handleSelectChange}
      disabled={!undefinedCheck(props.isActive)}
      size={"small"}
      sx={{
        backgroundColor: undefinedCheck(props.isActive)
          ? colors.white
          : colors.darkGrey,
        height: "25px",
        borderRadius: "5px",
      }}
    />
  );
};

const menuItems = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  label: i + 1,
}));
