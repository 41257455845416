const getGermanFormat = (date, options = {}) => {
  const defaultOptions = {
    month: "short",
    day: "numeric",
  };

  const finalOptions = { ...defaultOptions, ...options };

  return date.toLocaleDateString("de-DE", finalOptions);
};

export { getGermanFormat };
