import * as Sentry from "@sentry/react";

import { Provider as AlertProvider, positions, transitions } from "react-alert";

import AlertTemplate from "react-alert-template-basic";
import AppRouter from "../AppRouter";
import { AuthProvider } from "../context/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import HistoryContext from "../context/HistoryContext";
import { RoleProvider } from "../context/RoleProvider";
import THEME from "../styles/theme";
import { ThemeProvider } from "@mui/material";
import WindowSizeContext from "../context/WindowSizeContext";
import { useState } from "react";
import { useWindowSize } from "@reach/window-size";
import IEWarning from "./IEWarning";

// TODO add Proptypes literally everywhere
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  const windowSize = useWindowSize();

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "30px",
    transition: transitions.SCALE,
  };

  const [history, setHistory] = useState();

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <ThemeProvider theme={THEME}>
        <HistoryContext.Provider value={{ history, setHistory }}>
          <AlertProvider template={AlertTemplate} {...options}>
            <BrowserRouter>
              <AuthProvider>
                <RoleProvider>
                  <IEWarning>
                    <AppRouter />
                  </IEWarning>
                </RoleProvider>
              </AuthProvider>
            </BrowserRouter>
          </AlertProvider>
        </HistoryContext.Provider>
      </ThemeProvider>
    </WindowSizeContext.Provider>
  );
}

export default App;
