import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const ChartContainer = styled("div")`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 5vh;
  background-color: #f2f2f2;
  width: 90%;
  height: 100%;
`;

export const ChartHeading = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 94%;
`;

export const ChartHeadingRightWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LavenderSpan = styled("span")`
  color: ${colors.lavender};
`;

export const ResponsiveContainerWrapper = styled("div")`
  width: 95%;
  height: 68vh;
`;
