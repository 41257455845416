import { AreasTitle, GridContainer } from "./styles";
import React, { useEffect, useState } from "react";

import AddNewCustomArea from "../AreaMenuItems/AddNewCustomArea";
import AreaMenuItem from "../AreaMenuItems";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const AreaSelection = () => {
  const { getAreas, getAccount } = useLightsApiPrivate();
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCustomActive, setIsCustomActive] = useState(false);

  useEffect(() => {
    const fetchAccount = async () => {
      const account = await getAccount();
      setIsCustomActive(account.isCustomActive);
    };

    fetchAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAreas = async () => {
      const areas = await getAreas();
      setAreas(areas);
    };

    if (loading) {
      setLoading(false);
      fetchAreas();
    }
  }, [loading, getAreas]);

  const updateAreaList = async () => {
    const areas = await getAreas();
    setAreas(areas);
  };

  const normalAreas = areas.filter((area) => !area.isCustom);
  const customAreas = areas.filter(
    (area) => area.isCustom && area.status === "active"
  );

  return (
    <>
      <GridContainer>
        {isCustomActive && <AreasTitle>Diverlyze Bereiche</AreasTitle>}
        {normalAreas.map((area) => (
          <AreaMenuItem
            key={area._id}
            areaTitle={area.name}
            areaId={area._id}
            areaIdentifier={area.identifier}
            isCustom={area.isCustom}
          />
        ))}
      </GridContainer>
      {isCustomActive && (
        <GridContainer>
          <AreasTitle>Custom Bereiche</AreasTitle>
          {customAreas.map((area) => (
            <AreaMenuItem
              key={area._id}
              areaTitle={area.name}
              areaId={area._id}
              areaIdentifier={area.identifier}
              isCustom={area.isCustom}
            />
          ))}
          <AddNewCustomArea updateAreaList={updateAreaList} />
        </GridContainer>
      )}
    </>
  );
};

export default AreaSelection;
