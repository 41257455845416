import {
  AnswerRateSection,
  HeaderTextContainer,
  MediumSchedulerText,
  PrimaryToggleHeader,
  ScheduleBody,
  ScheduleContainer,
  ScheduleHeader,
  ScheduleText,
  Separator,
  VerticalSeparator,
} from "./styles";
import { DaySchedule, SchedulerLeftSide } from "./SchedulerLeftSide";
import { useEffect, useState } from "react";

import LoadingAnimation from "../../../../common/LoadingAnimation";
import { SchedulerRightSide } from "./SchedulerRightSide";
import colors from "../../../../common/colors";
//@ts-ignore
import scheduleImage from "../../../../../assets/images/schedule.png";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

const Scheduler = () => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState<DaySchedule[]>([]);
  const [answerrate, setAnswerRate] = useState<number>(0);
  const [isMailMinuteRandom, setIsMailMinuteRandom] = useState(false);
  const [activeEmployeeCount, setActiveEmployeeCount] = useState(0);
  const [expectedAnswers, setExpectedAnswers] = useState(0);

  const fetchData = async () => {
    const answerFormula = await getAnswerFormula();
    const account = await getAccount();
    setActiveEmployeeCount(answerFormula.employeeCount);
    setIsMailMinuteRandom(account.isMailMinuteRandom);
    setSchedule(account.schedule);
    setAnswerRate(answerFormula.answerrate);
    setLoading(false);
  };

  const { getAccount, getAnswerFormula } = useLightsApiPrivate();
  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const calculateExpectedAnswers = () => {
      let QuestionCount = 0;
      for (const entry of schedule) {
        if (entry.isActive) {
          QuestionCount += entry.amountQuestions;
        }
      }
      return Math.round(
        activeEmployeeCount * QuestionCount * (answerrate / 100) * 4
      );
    };
    setExpectedAnswers(calculateExpectedAnswers());
  }, [schedule, activeEmployeeCount, answerrate]);

  if (loading) return <LoadingAnimation />;

  return (
    <ScheduleContainer>
      <ScheduleHeader>
        <img src={scheduleImage} alt="schedule" height="150px" width="233px" />
        <HeaderTextContainer>
          <PrimaryToggleHeader>Plane die Zeiten.</PrimaryToggleHeader>
          <ScheduleText>
            Du kannst einstellen, an welchen Tagen Du Pulse-Checks versenden
            möchtest, wie viele Fragen Du pro Tag freischalten möchtest und zu
            welcher Uhrzeit Du eine Erinnerung per E-Mail an Deine
            Mitarbeitenden versenden möchtest. Die Beantwortungsfrist ist
            zeitlich unbegrenzt.
          </ScheduleText>
        </HeaderTextContainer>
      </ScheduleHeader>
      <Separator />
      <ScheduleBody>
        <SchedulerLeftSide setSchedules={setSchedule} schedules={schedule} />
        <VerticalSeparator />
        <SchedulerRightSide
          isMailMinuteRandom={isMailMinuteRandom}
          setIsMailMinuteRandom={setIsMailMinuteRandom}
        />
      </ScheduleBody>
      <AnswerRateSection>
        <MediumSchedulerText>
          Mit diesen Einstellungen werden bei der derzeitigen Antwortrate&nbsp;
          <MediumSchedulerText color={colors.lightGreen}>
            {expectedAnswers}
          </MediumSchedulerText>
          &nbsp;Antworten pro Monat erwartet.
        </MediumSchedulerText>
      </AnswerRateSection>
    </ScheduleContainer>
  );
};

export default Scheduler;
