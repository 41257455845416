import {
  LeftSideContainer,
  LeftSideHeaderContainer,
  RowContainer,
  ScheduleLeftSideText,
} from "./styles";

import { QuantityDropdown } from "./QuantityDropdown";
import { SwitchContainer } from "./SwitchContainer";
import { TimeDropdown } from "./TimeDropdown";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

export interface DaySchedule {
  hour: number;
  amountQuestions: number;
  weekday: number;
  isActive: boolean | undefined;
}

interface SchedulerLeftSideProps {
  schedules: DaySchedule[];
  setSchedules: (schedules: DaySchedule[]) => void;
}

export const SchedulerLeftSide = (props: SchedulerLeftSideProps) => {
  const { updateSchedule } = useLightsApiPrivate();

  props.setSchedules(props.schedules.sort((a, b) => a.weekday - b.weekday));

  const updateHour = (weekDay: number, newHour: number) => {
    const updateSchedules = props.schedules.map((schedule) => {
      if (schedule.weekday === weekDay) {
        updateSchedule({ ...schedule, hour: newHour });
        return { ...schedule, hour: newHour };
      }
      return schedule;
    });
    props.setSchedules(updateSchedules);
  };

  const updateQuantity = (weekday: number, newQuantity: number) => {
    const updateSchedules = props.schedules.map((schedule) => {
      if (schedule.weekday === weekday) {
        updateSchedule({ ...schedule, amountQuestions: newQuantity });
        return { ...schedule, amountQuestions: newQuantity };
      }
      return schedule;
    });
    props.setSchedules(updateSchedules);
  };

  const toggleActive = (weekday: number) => {
    const updateSchedules = props.schedules.map((schedule) => {
      if (schedule.weekday === weekday) {
        updateSchedule({ ...schedule, isActive: !schedule.isActive });
        return { ...schedule, isActive: !schedule.isActive };
      }
      return schedule;
    });
    props.setSchedules(updateSchedules);
  };

  return (
    <LeftSideContainer>
      <LeftSideHeaderContainer>
        <ScheduleLeftSideText isActive={true}>
          Pulse-Check-Tage
        </ScheduleLeftSideText>
        <ScheduleLeftSideText isActive={true}>
          Fragenanzahl
        </ScheduleLeftSideText>
        <ScheduleLeftSideText isActive={true}>
          Uhrzeit Erinnerung
        </ScheduleLeftSideText>
      </LeftSideHeaderContainer>
      {props.schedules.map((schedule) => (
        <RowContainer>
          <SwitchContainer
            day={schedule.weekday}
            isActive={schedule.isActive}
            toggleActive={toggleActive}
          />
          <QuantityDropdown
            quantity={schedule.amountQuestions}
            day={schedule.weekday}
            updateQuantity={updateQuantity}
            isActive={schedule.isActive}
          />
          <TimeDropdown
            isActive={schedule.isActive}
            hour={schedule.hour}
            updateHour={updateHour}
            day={schedule.weekday}
          />
        </RowContainer>
      ))}
    </LeftSideContainer>
  );
};
