import colors from "../../../../../common/colors";
import styled from "@emotion/styled";

interface ScheduleLeftSideTextProps {
  isActive?: boolean;
}

export const ScheduleLeftSideText = styled("div")<ScheduleLeftSideTextProps>`
  font-size: 16px;
  text-color: white;
  font-weight: 500;
  color: ${(props) => (props.isActive ? colors.white : colors.darkGrey)};
`;

export const RowContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
  align-items: center;
  gap: 40px;
`;

export const LeftSideContainer = styled("div")`
  display: grid;
  grid-auto-rows: min-content;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const LeftSideHeaderContainer = styled("div")`
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
  align-items: center;
  gap: 40px;
`;
