import {
  AddQuestionButton,
  Container,
  IconContainer,
  ScoreContainer,
  SectionHeader,
  TitleContainer,
} from "./styles";

import AddQuestionForm from "./AddQuestionForm";
import BackButton from "../../settings/BackButton";
import Heading2 from "../Heading2";
import React from "react";
import Text from "../Text";
import TextWithTooltip from "../TextWithTooltip";
import { useNavigate } from "react-router-dom";
import usePortalSize from "../../../hooks/usePortalSize";
import { useState } from "react";

const AreaSection = ({
  content,
  children,
  backLinkTo,
  isCustom,
  areaName,
  areaId,
  updateQuestionList,
}) => {
  const navigate = useNavigate();
  const size = usePortalSize();
  const [modalOpen, setModalOpen] = useState(false);

  const { title, infoText, hoverText, id } = content;
  const hasBackButton = backLinkTo !== undefined;

  const showAddQuestionButton = content.id === "question" && isCustom;
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container className={size}>
      {hasBackButton && <BackButton onClick={() => navigate(backLinkTo)} />}
      <SectionHeader className={size}>
        <TitleContainer>
          <Heading2>{title}</Heading2>
          {infoText && <Text fontSize="16px">{infoText}</Text>}
        </TitleContainer>
        <IconContainer>
          {hoverText && (
            <TextWithTooltip text={hoverText} id={id}></TextWithTooltip>
          )}
        </IconContainer>
        {showAddQuestionButton && (
          <AddQuestionButton variant="contained" onClick={handleOpenModal}>
            Frage hinzufügen
          </AddQuestionButton>
        )}
      </SectionHeader>
      <ScoreContainer>{children}</ScoreContainer>
      <AddQuestionForm
        open={modalOpen}
        handleClose={handleCloseModal}
        areaName={areaName}
        areaId={areaId}
        updateQuestionList={updateQuestionList}
      />
    </Container>
  );
};

export default AreaSection;
