import { ConfirmDeleteArea } from "./ConfirmDeleteArea";
import { DeleteButton } from "./styles";
import React from "react";
import { useState } from "react";

export const DeleteCustomArea = ({ areaId, areaName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <DeleteButton
        onClick={handleDeleteClick}
        variant="contained"
        size="small"
      >
        Bereich löschen
      </DeleteButton>
      <ConfirmDeleteArea
        areaName={areaName}
        areaId={areaId}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};
