import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const QuestionGrid = styled("div")`
  display: grid;
  width: 90%;
  row-gap: 20px;
  grid-template-columns: auto auto;
  column-gap: max;
`;

export const QuestionGridContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const LeftQuestionCard = styled("div")`
  justify-self: start;
`;

export const RightQuestionCard = styled("div")`
  justify-self: end;
`;
export const NoQuestionText = styled("p")`
  font-size: 20px;
  color: ${colors.darkGrey};
  margin-top: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
`;
