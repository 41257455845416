import * as Components from "./styles";

import AreaIcon from "../../../report/AreaIcon";
import { Flex } from "@react-spectrum/layout";
import React from "react";

const DetailsSection = ({
  isCustom,
  areaName,
  areaInfoText,
  areaIdentifier,
}) => {
  return (
    <Flex direction="column">
      <Components.Container>
        <Components.AreaIconContainer isCustom={isCustom}>
          <AreaIcon type={areaIdentifier} />
        </Components.AreaIconContainer>
        <Components.AreaName>{areaName}</Components.AreaName>
        <Components.AreaInfoText>{areaInfoText}</Components.AreaInfoText>
      </Components.Container>
    </Flex>
  );
};

export default React.memo(DetailsSection);
