import {
  ButtonContainer,
  CloseIcon,
  ErrorText,
  FormContainer,
  FormTextFieldDescription,
  FormTextFieldTitle,
  FormTitle,
} from "./styles";

import { Modal } from "@mui/material";
import React from "react";
import { createCustomArea } from "../../../../../../api/lightsApi";
import { useAlert } from "react-alert";
import { useState } from "react";
import { validateNewCustomArea } from "./formValidation";

const MAX_DESCRIPTION_LENGTH = 150;
const MAX_TITLE_LENGTH = 18;

const AddCustomAreaForm = ({ updateAreaList, open, handleClose }) => {
  const [name, setName] = useState("");
  const [infoText, setInfoText] = useState("");
  const [error, setError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const alert = useAlert();

  const handleTitleChange = (e) => {
    const input = e.target.value;
    if (input.length <= MAX_TITLE_LENGTH) {
      setName(input);
      setError(false);
      setTitleError(false);
    } else {
      setTitleError(true);
      setError(
        "Du hast die maximale Anzahl an Zeichen für den Titel erreicht."
      );
    }
  };

  const handleDescriptionChange = (e) => {
    const input = e.target.value;
    if (input.length <= MAX_DESCRIPTION_LENGTH) {
      setInfoText(input);
      setError(false);
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
      setError(
        "Du hast die maximale Anzahl an Zeichen für die Beschreibung erreicht."
      );
    }
  };

  const handleSubmit = async (e) => {
    const areaData = {
      name,
      infoText,
    };
    const { error } = validateNewCustomArea(areaData);

    if (error) {
      if (error.details[0].context.key === "name") {
        setTitleError(true);
      } else if (error.details[0].context.key === "infoText") {
        setDescriptionError(true);
      }
      setError(error.details[0].message);
      return;
    }

    try {
      await createCustomArea(areaData);
      handleClose();
      alert.success("Erfolgreich hinzugefügt");
      await updateAreaList();
      setName("");
      setInfoText("");
    } catch (error) {
      alert.error("Es ist ein Fehler aufgetreten");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-name"
      aria-describedby="modal-infoText"
    >
      <FormContainer>
        <CloseIcon onClick={handleClose} />
        <FormTitle>Erstelle einen neuen Custom Bereich</FormTitle>
        <FormTextFieldTitle
          label="Titel"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={handleTitleChange}
          error={titleError}
        />
        <FormTextFieldDescription
          label="Beschreibung"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={infoText}
          error={descriptionError}
          onChange={handleDescriptionChange}
        />
        {error && <ErrorText>{error}</ErrorText>}
        <ButtonContainer
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Hinzufügen
        </ButtonContainer>
      </FormContainer>
    </Modal>
  );
};

export default React.memo(AddCustomAreaForm);
