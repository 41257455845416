import {
  ActionHint,
  Buttons,
  Card,
  Data,
  LoadingContainer,
  SectionContainer,
  SectionContainerDetail,
  StyledButton,
} from "./styles";

import DetailsSection from "./DetailsSection";
import { Flex } from "@react-spectrum/layout";
import LoadingSection from "./LoadingSection";
import React from "react";
import ScoresSection from "./ScoresSection";
import { useNavigate } from "react-router-dom";

const ScoreCard = ({ score }) => {
  const navigate = useNavigate();

  const {
    areaId,
    activity,
    areaInfoText,
    areaName,
    areaIdentifier,
    ratingCombined,
    trendRatingCombined,
    answersLeft,
    amountComments,
    avgRating,
    isCustom,
    createdAt,
    amountQuestions,
  } = score;

  const loading = !ratingCombined;
  const mainScore = ratingCombined;

  const navigateToActions = () => {
    navigate(`/areas/${areaId}/report/actions`);
  };

  const showActions = !isCustom && areaIdentifier !== "workLifeBalance";

  return (
    <Card isCustom={isCustom}>
      <Flex direction="column">
        <Data>
          <Flex>
            <SectionContainerDetail loading={loading}>
              <DetailsSection
                isCustom={isCustom}
                areaIdentifier={areaIdentifier}
                areaName={areaName}
                areaInfoText={areaInfoText}
              />
            </SectionContainerDetail>
            <SectionContainer>
              {loading ? (
                <LoadingContainer isCustom={isCustom}>
                  <LoadingSection answersLeft={answersLeft} id={areaId} />
                </LoadingContainer>
              ) : (
                <ScoresSection
                  activity={activity}
                  areaInfoText={areaInfoText}
                  areaName={areaName}
                  mainScore={mainScore}
                  amountComments={amountComments}
                  avgRating={avgRating}
                  trendRatingCombined={trendRatingCombined}
                  isCustom={isCustom}
                  amountQuestions={amountQuestions}
                />
              )}
            </SectionContainer>
          </Flex>
        </Data>
        <Buttons>
          <Flex>
            <SectionContainer>
              {!loading && showActions && (
                <ActionHint onClick={() => navigateToActions()}>
                  Maßnahmen
                </ActionHint>
              )}
              {isCustom && (
                <ActionHint isCustom={isCustom}>
                  {`Aktiv seit ${createdAt}`}
                </ActionHint>
              )}
            </SectionContainer>
            {!loading && (
              <SectionContainer>
                <StyledButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigate(`/areas/${areaId}/report`);
                    window.scrollTo(0, 0);
                  }}
                  isCustom={isCustom}
                >
                  Zu den Score Details
                </StyledButton>
              </SectionContainer>
            )}
          </Flex>
        </Buttons>
      </Flex>
    </Card>
  );
};

export default React.memo(ScoreCard);
