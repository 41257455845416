import {
  MailSplitSection,
  MailSplitSwitchContainer,
  RightSideContainer,
  RowContainer,
} from "./styles";
import {
  MediumSchedulerText,
  PrimaryToggleHeader,
  ScheduleText,
} from "../styles";

import { MailSplitSwitch } from "./MailSplitSwitch";
//@ts-ignore
import { ReactComponent as SchedulerClock } from "../../../../../../icons/scheduler_clock.svg";

interface SchedulerRightSideProps {
  isMailMinuteRandom: boolean;
  setIsMailMinuteRandom: (isMailMinuteRandom: boolean) => void;
}

export const SchedulerRightSide = (props: SchedulerRightSideProps) => {
  return (
    <RightSideContainer>
      <RowContainer>
        <SchedulerClock width="81px" height="84px" />
        <div>
          <PrimaryToggleHeader>Sendezeitpunkt aufteilen?</PrimaryToggleHeader>
          <ScheduleText>
            Du kannst den Versand über eine Stunde verteilen, um die
            Wahrscheinlichkeit zu verringern, dass die Pulse-Check-Mails als
            Spam markiert werden.
          </ScheduleText>
        </div>
      </RowContainer>
      <MailSplitSection>
        <MailSplitSwitchContainer>
          <MailSplitSwitch
            isMailMinuteRandom={props.isMailMinuteRandom}
            setIsMailMinuteRandom={props.setIsMailMinuteRandom}
          />
          <MediumSchedulerText>
            {props.isMailMinuteRandom
              ? "Alle E-Mails seperat 1 bis 60 Minuten nach der gewählten Uhrzeit versenden."
              : "Nein, alle E-Mails gleichzeitig zur gewählten Uhrzeit versenden."}
          </MediumSchedulerText>
        </MailSplitSwitchContainer>
      </MailSplitSection>
    </RightSideContainer>
  );
};
