import Joi from "joi";

const newCustomQuestionSchema = Joi.object({
  questionText: Joi.string().max(150).required().messages({
    "string.empty": "Eine Frage ist erforderlich.",
  }),
});

const validateNewQuestion = (creds) => {
  return newCustomQuestionSchema.validate(creds);
};

export { validateNewQuestion };
