import {
  createAnswer,
  createEmployee,
  createEmployees,
  createTeam,
  deactivateEmployee,
  deleteCustomArea,
  deleteCustomQuestion,
  deleteEmployee,
  deleteEmployees,
  deleteTeam,
  getAccount,
  getAccountDimensions,
  getActions,
  getAdminDialogs,
  getAnswerFormula,
  getAreas,
  getChatMessages,
  getDiversityOverview,
  getDiversityScoresForAreas,
  getDiversityScoresForQuestions,
  getEmployee,
  getEmployeePD,
  getEmployees,
  getMainScore,
  getQuestions,
  getScoreOverTime,
  getScores,
  getSurveeDialogs,
  getTeam,
  getTeams,
  getTwofaCheck,
  getUnansweredQuestions,
  lightsApiPrivate,
  patchAccountPrefix,
  patchAnswerComment,
  patchDialogRead,
  patchIsMailMinuteRandom,
  patchQuestionCommentsRead,
  postChatMessage,
  postDialog,
  postEmployeeInvite,
  postEmployeeReminder,
  postEmployeesInvite,
  postEmployeesReminder,
  postSelectedEmployeeInvite,
  postTwofaCode,
  postTwofaResend,
  reactivateEmployee,
  sendActionRequest,
  setAccountDimensions,
  updateEmployee,
  updateSchedule,
  updateTeam,
} from "../api/lightsApi";

import useAuth from "./useAuth";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";

/**
 * a hook that adds the authentication token to the request headers from
 * lights api and returns all lights api requests that need
 * authentication.
 */
const useLightsApiPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  const requestIntercept = lightsApiPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
      }
      return config;
    },
    (e) => Promise.reject(e)
  );

  useEffect(() => {
    const responseIntercept = lightsApiPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          return lightsApiPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      lightsApiPrivate.interceptors.request.eject(requestIntercept);
      lightsApiPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh, requestIntercept]);

  return {
    getAccount: () => getAccount(lightsApiPrivate),
    getAreas: () => getAreas(lightsApiPrivate),
    getDiversityOverview: () => getDiversityOverview(lightsApiPrivate),
    getDiversityScoresForAreas: (selectedDimension) =>
      getDiversityScoresForAreas(lightsApiPrivate, selectedDimension),
    getDiversityScoresForQuestions: (selectedArea, selectedDimension) =>
      getDiversityScoresForQuestions(
        lightsApiPrivate,
        selectedArea,
        selectedDimension
      ),
    getScores: () => getScores(lightsApiPrivate),
    getMainScore: () => getMainScore(lightsApiPrivate),
    getAccountDimensions: () => getAccountDimensions(lightsApiPrivate),
    getTeams: () => getTeams(lightsApiPrivate),
    getEmployees: () => getEmployees(lightsApiPrivate),
    deactivateEmployee: (id) => deactivateEmployee(lightsApiPrivate, id),
    reactivateEmployee: (id) => reactivateEmployee(lightsApiPrivate, id),
    getEmployeePD: () => getEmployeePD(lightsApiPrivate),
    getUnansweredQuestions: (id) =>
      getUnansweredQuestions(lightsApiPrivate, id),
    getTeam: (id) => getTeam(lightsApiPrivate, id),
    patchQuestionCommentsRead: (id) =>
      patchQuestionCommentsRead(lightsApiPrivate, id),
    deleteTeam: (id) => deleteTeam(lightsApiPrivate, id),
    deleteCustomArea: (id) => deleteCustomArea(lightsApiPrivate, id),
    getEmployee: (id) => getEmployee(lightsApiPrivate, id),
    getQuestions: (id) => getQuestions(lightsApiPrivate, id),
    deleteCustomQuestion: (id) => deleteCustomQuestion(lightsApiPrivate, id),
    deleteEmployee: (id) => deleteEmployee(lightsApiPrivate, id),
    deleteEmployees: (payload) => deleteEmployees(lightsApiPrivate, payload),
    postEmployeesInvite: (payload) =>
      postEmployeesInvite(lightsApiPrivate, payload),
    postEmployeeInvite: (id) => postEmployeeInvite(lightsApiPrivate, id),
    postSelectedEmployeeInvite: (payload) =>
      postSelectedEmployeeInvite(lightsApiPrivate, payload),
    postEmployeesReminder: (payload) =>
      postEmployeesReminder(lightsApiPrivate, payload),
    getAdminDialogs: () => getAdminDialogs(lightsApiPrivate),
    getSurveeDialogs: () => getSurveeDialogs(lightsApiPrivate),
    getChatMessages: (id) => getChatMessages(lightsApiPrivate, id),
    patchDialogRead: (id) => patchDialogRead(lightsApiPrivate, id),
    getScoreOverTime: (payload) => getScoreOverTime(lightsApiPrivate, payload),
    getActions: (payload) => getActions(lightsApiPrivate, payload),
    setAccountDimensions: (payload) =>
      setAccountDimensions(lightsApiPrivate, payload),
    createEmployee: (payload) => createEmployee(lightsApiPrivate, payload),
    createEmployees: (payload) => createEmployees(lightsApiPrivate, payload),
    updateSchedule: (payload) => updateSchedule(lightsApiPrivate, payload),
    createTeam: (payload) => createTeam(lightsApiPrivate, payload),
    postChatMessage: (payload) => postChatMessage(lightsApiPrivate, payload),
    postDialog: () => postDialog(lightsApiPrivate),
    updateEmployee: (id, payload) =>
      updateEmployee(lightsApiPrivate, id, payload),
    updateTeam: (id, payload) => updateTeam(lightsApiPrivate, id, payload),
    patchAnswerComment: (id, payload) =>
      patchAnswerComment(lightsApiPrivate, id, payload),
    postTwofaCode: (payload) => postTwofaCode(payload),
    createAnswer: (payload) => createAnswer(payload),
    getTwofaCheck: () => getTwofaCheck(),
    postTwofaResend: () => postTwofaResend(),
    sendActionRequest: (id) => sendActionRequest(lightsApiPrivate, id),
    patchAccountPrefix: (payload) =>
      patchAccountPrefix(lightsApiPrivate, payload),
    postEmployeeReminder: (id) => postEmployeeReminder(lightsApiPrivate, id),
    getAnswerFormula: () => getAnswerFormula(lightsApiPrivate),
    patchIsMailMinuteRandom: (payload) =>
      patchIsMailMinuteRandom(lightsApiPrivate, payload),
  };
};

export default useLightsApiPrivate;
