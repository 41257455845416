import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const ScheduleContainer = styled("div")`
  padding: 30px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.midnight};
  border-radius: 10px;
  height: auto;
  width: 90%;
  margin-bottom: 14px;
`;

export const Separator = styled("hr")`
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
  border-top: 2px solid ${colors.lavender};
  width: 100%;
`;

export const VerticalSeparator = styled("span")`
  width: 4px;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
  background-color: ${colors.lavender};
`;

export const HeaderTextContainer = styled("div")`
  margin-left: 20px;
`;

export const ScheduleHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-self: flex-start;
  width: 90%;
`;

export const ScheduleBody = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const PrimaryToggleHeader = styled("div")`
  font-weight: 500;
  font-size: 20px;
  color: ${colors.white};
`;

export const AnswerRateSection = styled("div")`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: left;
`;

interface MediumSchedulerTextProps {
  color?: string;
}

export const MediumSchedulerText = styled("text")<MediumSchedulerTextProps>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color || "white"};
`;

export const ToggleText = styled("h2")`
  font-weight: 400;
  font-size: 18px;
  color: ${colors.white};
  margin: 8px 0 0 0;
`;

export const ScheduleText = styled("h2")`
  font-size: 16px;
  font-weight: normal;
  color: ${colors.white};
  line-height: 1.2;
  max-width: 100%;
`;
