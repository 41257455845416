import { StyledSwitch, ToggleContainer } from "./styles";

import { ScheduleLeftSideText } from "../styles";

interface SwitchContainerProps {
  day: number;
  toggleActive: (day: number) => void;
  isActive: boolean | undefined;
}

const undefinedCheck = (value: boolean | undefined) => {
  return value ? value : false;
};

export const SwitchContainer = (props: SwitchContainerProps) => {
  const handleToggle = (event: React.ChangeEvent) => {
    props.toggleActive(parseInt(event.target.id));
  };
  return (
    <ToggleContainer>
      <StyledSwitch
        key={props.day}
        id={props.day as unknown as string}
        checked={undefinedCheck(props.isActive)}
        onChange={handleToggle}
      />
      <ScheduleLeftSideText isActive={undefinedCheck(props.isActive)}>
        {weekDays[props.day]}
      </ScheduleLeftSideText>
    </ToggleContainer>
  );
};

const weekDays = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
