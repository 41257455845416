import styled from "@emotion/styled";

export const AreaName = styled("div")`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  height: 20px;
  margin-top: 5px;
`;
export const Container = styled("div")`
  min-width: 200px;
`;
export const AreaIconContainer = styled("div")`
  margin-bottom: 5px;
  margin-top: ${({ isCustom }) => (isCustom ? "25px" : "15px")};
  height: 50px;
`;

export const AreaInfoText = styled("div")`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  width: 100%;
  max-width: 200px;
  word-break: break-word;
`;
