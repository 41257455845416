import Joi from "joi";

const newCustomAreaSchema = Joi.object({
  name: Joi.string().max(18).required().messages({
    "string.empty": "Titel ist erforderlich.",
    "string.max": "Titel darf maximal 24 Zeichen lang sein.",
  }),
  infoText: Joi.string().max(150).required().messages({
    "string.empty": "Beschreibung ist erforderlich.",
    "string.max": "Beschreibung darf maximal 150 Zeichen lang sein.",
  }),
});

const validateNewCustomArea = (creds) => {
  return newCustomAreaSchema.validate(creds, { abortEarly: false });
};

export { validateNewCustomArea };
