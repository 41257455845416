import * as Sentry from "@sentry/react";

import {
  Button,
  DropdownContainer,
  DropdownIcon,
  Label,
  ListItem,
  menuStyles,
  menuStylesClosed,
} from "./styles";
import { dayTimes, timeStrings } from "./dayTime";

import React from "react";
import colors from "../../../../common/colors";
import dropdown_arrow from "../../../../../assets/images/dropdown_arrow.png";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";
import { useSelect } from "downshift";

const Dropdown = ({ day, dayIndex, curHour, labelColor = colors.white }) => {
  const { updateSchedule } = useLightsApiPrivate();

  const sendScheduleUpdate = async (dropdownIndex) => {
    const hour = dayTimes[dropdownIndex];

    try {
      await updateSchedule({
        weekday: dayIndex,
        hour,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  function DropdownSelect() {
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect({ items: timeStrings });

    const hourString = curHour ? `${curHour}:00 Uhr` : null;

    const buttonText = selectedItem || hourString || "kein Survey";
    const activeSchedule = buttonText !== "kein Survey";

    return (
      <DropdownContainer>
        <Label {...getLabelProps()} color={labelColor}>
          {day}
        </Label>
        <Button
          type="button"
          {...getToggleButtonProps()}
          activeSchedule={activeSchedule}
        >
          {selectedItem || hourString || "kein Survey"}
          <DropdownIcon src={dropdown_arrow} alt="dropdown_arrow" />
        </Button>
        <ul {...getMenuProps()} style={isOpen ? menuStylesClosed : menuStyles}>
          {isOpen &&
            timeStrings.map((item, index) => (
              <ListItem
                onMouseUp={() => {
                  sendScheduleUpdate(index);
                }}
                style={
                  highlightedIndex === index
                    ? { backgroundColor: `${colors.lightGrey}` }
                    : {}
                }
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                {item}
              </ListItem>
            ))}
        </ul>
      </DropdownContainer>
    );
  }
  return <DropdownSelect />;
};
export default Dropdown;
