import { Button } from "@mui/material";
import colors from "../colors";
import styled from "@emotion/styled";

export const AddQuestionButton = styled(Button)`
  margin-right: 80px;
`;
export const Container = styled("div")`
  width: 90%;
  max-width: 1300px;
  top: 45px;
  border: 1px solid ${colors.darkGrey};
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;

  &.s {
    align-items: center;
    justify-content: center;
  }
`;

export const SectionHeader = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.s {
    justify-content: center;
  }
`;

export const TitleContainer = styled("div")`
  width: fit-content;
  margin: 14px;
  margin-left: 2%;
  width: 500px;
`;

export const IconContainer = styled("div")`
  align-self: start;
`;

export const ScoreContainer = styled("div")`
  margin-top: 14px;
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
