import {
  ButtonContainer,
  CloseIcon,
  ErrorText,
  FormContainer,
  FormTextFieldQuestion,
  FormTitle,
  OverlayContainer,
  ReviewInfoText,
} from "./styles";

import LoadingAnimation from "../../LoadingAnimation";
import { Modal } from "@mui/material";
import React from "react";
import { createCustomQuestion } from "../../../../api/lightsApi";
import { useAlert } from "react-alert";
import { useState } from "react";
import { validateNewQuestion } from "./formValidation";

const MAX_QUESTION_LENGTH = 150;

const AddQuestionForm = ({
  open,
  handleClose,
  areaName,
  areaId,
  updateQuestionList,
}) => {
  const [questionText, setQuestionText] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const handleChange = (e) => {
    const input = e.target.value;
    if (input.length <= MAX_QUESTION_LENGTH) {
      setQuestionText(input);
      setError(false);
    } else {
      setError("Die maximale Zeichenanzahl für diese Frage ist erreicht.");
    }
  };

  const handleSubmit = async () => {
    const { error } = validateNewQuestion({ questionText });
    if (error) {
      if (error.details[0].context.key === "questionText") {
        setError(error.details[0].message);
        return;
      }
    }

    try {
      setIsLoading(true);
      const newQuestion = {
        question: questionText,
        areaId: areaId,
      };
      await createCustomQuestion(newQuestion);
      alert.success("Frage erfolgreich hinzugefügt");
      setIsLoading(false);
      handleClose();
      setQuestionText("");
      setError(false);
    } catch (error) {
      alert.error("Es ist ein Fehler aufgetreten.");
      setIsLoading(false);
      handleClose();
    }
    updateQuestionList();
  };

  const handleCloseModal = () => {
    setQuestionText("");
    setError(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <FormContainer error={error}>
        <CloseIcon onClick={handleCloseModal} />
        <FormTitle>
          Erstelle eine neue Frage für den
          <br />
          Bereich {areaName}
        </FormTitle>
        <FormTextFieldQuestion
          label="Frage"
          variant="outlined"
          fullWidth
          margin="normal"
          value={questionText}
          multiline
          rows={4}
          onChange={handleChange}
        />
        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          <ReviewInfoText>
            Bitte beachten: Nach dem Hinzufügen erhält die Frage zunächst den
            Status 'in Prüfung' und wird von Diverlyze geprüft, bevor sie
            freigeschaltet wird.
          </ReviewInfoText>
        )}
        <ButtonContainer
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          error={error}
        >
          Hinzufügen
        </ButtonContainer>

        {isLoading && (
          <OverlayContainer>
            <LoadingAnimation />
          </OverlayContainer>
        )}
      </FormContainer>
    </Modal>
  );
};
export default React.memo(AddQuestionForm);
