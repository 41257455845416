import { StyledSwitch } from "./styles";
import useLightsApiPrivate from "../../../../../../../hooks/useLightsApiPrivate";

interface MailSplitSwitchProps {
  isMailMinuteRandom: boolean;
  setIsMailMinuteRandom: (isMailMinuteRandom: boolean) => void;
}

export const MailSplitSwitch = (props: MailSplitSwitchProps) => {
  const { patchIsMailMinuteRandom } = useLightsApiPrivate();

  const handleToggle = () => {
    patchIsMailMinuteRandom({ isMailMinuteRandom: !props.isMailMinuteRandom });
    props.setIsMailMinuteRandom(!props.isMailMinuteRandom);
  };

  return (
    <StyledSwitch onChange={handleToggle} checked={props.isMailMinuteRandom} />
  );
};
