import styled from "@emotion/styled";

export const ModalTitle = styled("p")`
  margin-bottom: 0px;
  margin-top: 3px;
  width: 100%;
  font-family: "Rubik";
  font-weight: 400;
  padding: 0;
  font-size: 17px;
  margin-top: 0px;
`;

export const ButtonContainer = styled("div")`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Container = styled("div")`
  width: 510px;
`;

export const AreaNameBold = styled("span")`
  font-weight: 500;
`;
