import { Button, TextField } from "@mui/material";

import { CloseRounded } from "@mui/icons-material";
import colors from "../../../../../common/colors";
import styled from "@emotion/styled";

export const CloseIcon = styled(CloseRounded)`
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  float: right;
`;

export const FormContainer = styled("div")`
  height: fit-content;
  width: 540px;
  height: 365px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  border: 2px solid ${colors.grey};
  margin-top: 200px;
  box-shadow: 0;
  outline: none;
`;

export const FormTitle = styled("h3")`
  font-weight: 500;
  margin-top: 35px;
  font-size: 20px;
  margin-left: 30px;
`;
export const FormTextFieldTitle = styled(TextField)`
  width: 270px;
  height: 50px;
  margin-left: 30px;
  margin-top: -2px;
`;

export const FormTextFieldDescription = styled(TextField)`
  width: 470px;
  height: 150px;
  margin-left: 30px;
  margin-top: 20px;
`;

export const ButtonContainer = styled(Button)`
  display: flex;
  justify-content: center;
  width: 130px;
  background-color: ${colors.lavender};
  margin-left: auto;
  margin-top: -8px;
  margin-right: auto;
`;

export const ErrorText = styled("p")`
  color: ${colors.red};
  margin-left: 30px;
  margin-top: -25px;
  font-size: 14px;
`;
