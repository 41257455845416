import { ButtonContainer, ModalTitle, QuestionContent } from "./styles";

import Button from "@mui/material/Button";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

const ConfirmDelete = ({
  open,
  handleClose,
  questionContent,
  questionId,
  updateQuestionList,
}) => {
  const alert = useAlert();
  const { deleteCustomQuestion } = useLightsApiPrivate();
  const onDelete = async () => {
    try {
      await deleteCustomQuestion(questionId);
      handleClose();
      alert.success("Erfolgreich gelöscht");
      updateQuestionList();
    } catch (error) {
      alert.error("Es ist ein Fehler aufgetreten");
    }
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={getModalStyle()}
      contentLabel="Question Deletion Modal"
    >
      <ModalTitle>
        Bist du sicher, dass du die folgende Frage aus dem Bereich löschen
        willst:
      </ModalTitle>
      <QuestionContent>"{questionContent}"</QuestionContent>
      <ButtonContainer>
        <Button onClick={handleClose} variant="neutral">
          Abbrechen
        </Button>
        <Button onClick={onDelete} variant="warning">
          Löschen
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
export default ConfirmDelete;
