import { Button } from "@mui/material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const DeleteButton = styled(Button)`
  background-color: ${colors.red};
  color: white;
  display: flex;
  align-self: flex-start;
  margin-left: 25px;
  margin-top: -25px;
  &:hover {
    background-color: ${colors.darkRed};
  }
`;
