import axios from "axios";
const BASE_URL =
  process.env.REACT_APP_LIGHTS_API_URL || "http://localhost:8080/1.0";

const lightsApi = axios.create({
  baseURL: BASE_URL,
});

export const lightsApiPrivate = axios.create({
  baseURL: BASE_URL,
  header: { "Content-Type": "application/json" },
  withCredentials: true,
});

export async function createAnswer(payload) {
  const { data } = await lightsApi.post(`/answer`, payload);
  return data;
}

export async function patchAnswerComment(api, id, payload) {
  const { data } = await api.patch(`/answer/${id}/comment`, payload);
  return data;
}

export async function login(payload) {
  const { data } = await lightsApi.post(`/login`, payload, {
    "Content-type": "application/json",
    withCredentials: true,
  });
  return data;
}

export async function postTwofaCode(payload) {
  const { data } = await lightsApi.post(`/auth/twofa`, payload, {
    "Content-type": "application/json",
    withCredentials: true,
  });
  return data;
}

export async function postTwofaResend() {
  const { data } = await lightsApi.get(`/auth/twofa/resend`, {
    "Content-type": "application/json",
    withCredentials: true,
  });
  return data;
}

export async function getTwofaCheck() {
  const { data } = await lightsApi.get(`/auth/twofa/check`, {
    "Content-type": "application/json",
    withCredentials: true,
  });
  return data;
}

export async function logout(payload) {
  const { data } = await lightsApi.post(`/logout`, payload, {
    "Content-type": "application/json",
    withCredentials: true,
  });
  return data;
}

export async function resetPassword(payload) {
  const { data } = await lightsApi.post(`/reset-password`, payload);
  return data;
}

export async function createPassword(payload) {
  const { data } = await lightsApi.post(`/create-password`, payload);
  return data;
}

export async function getRefresh() {
  const { data } = await lightsApi.get(`/refresh`, {
    withCredentials: true,
  });
  return data;
}

export async function getAskedQuestion(id) {
  const { data } = await lightsApi.get(`/askedQuestion/${id}`);
  return data;
}

export async function getUnansweredQuestions(api, employeeId) {
  const { data } = await api.get(`/askedQuestions/unanswered`, {
    params: { employeeId },
  });
  return data;
}

export async function getUnansweredQuestionsAmount(askedQuestionId) {
  const { data } = await lightsApi.get(`/askedQuestions/unanswered/amount`, {
    params: { askedQuestionId },
  });
  return data;
}

export async function getQuestion(id) {
  const { data } = await lightsApi.get(`/question/${id}`);
  return data;
}

export async function getQuestionByAskedQuestion(id) {
  const { data } = await lightsApi.get(`/askedquestion/${id}/question`);
  return data;
}

export async function downloadCsvInstructions() {
  const { data } = await lightsApi.get(`/csv/instructions/download`, {
    responseType: "blob",
  });
  return data;
}

export async function downloadCsv() {
  const { data } = await lightsApi.get(`/csv/download`, {
    responseType: "blob",
  });
  return data;
}

// secured api
export async function getScores(api) {
  const { data } = await api.get(`/scores`);
  return data;
}

export async function getMainScore(api) {
  const { data } = await api.get(`/score/main`);
  return data;
}

export async function getScoreOverTime(api, areaId) {
  if (areaId) {
    const { data } = await api.get(`/score/timeline/${areaId}`);
    return data;
  } else {
    const { data } = await api.get(`/score/timeline`);
    return data;
  }
}

export async function getAccountDimensions(api) {
  const { data } = await api.get(`/account/dimensions`);
  return data;
}

export async function setAccountDimensions(api, payload) {
  const { data } = await api.put(`/account/dimensions`, payload);
  return data;
}

export async function getTeams(api) {
  const { data } = await api.get(`/teams`);
  return data;
}

export async function getTeam(api, id) {
  const { data } = await api.get(`/team/${id}`);
  return data;
}

export async function createTeam(api, payload) {
  const { data } = await api.post(`/team`, payload);
  return data;
}

export async function deleteTeam(api, id) {
  const { data } = await api.delete(`/team/${id}`);
  return data;
}

export async function updateTeam(api, id, payload) {
  const { data } = await api.put(`/team/${id}`, payload);
  return data;
}

export async function getEmployees(api) {
  const { data } = await api.get(`/employees`);
  return data;
}

export async function getEmployee(api, id) {
  const { data } = await api.get(`/employee/${id}`);
  return data;
}

export async function getEmployeePD(api) {
  const { data } = await api.get(`/pd`);
  return data;
}

export async function updateEmployee(api, id, payload) {
  const { data } = await api.put(`/employee/${id}`, payload);
  return data;
}

export async function createEmployee(api, payload) {
  const { data } = await api.post(`/employee`, payload);
  return data;
}

export async function createEmployees(api, payload) {
  const { data } = await api.post(`/employees`, payload);
  return data;
}

export async function deleteEmployee(api, id) {
  const { data } = await api.delete(`/employee/${id}`);
  return data;
}

export async function deleteEmployees(api, employeeIds) {
  const { data } = await api.delete(`employees/selected`, {
    params: { ids: employeeIds },
  });
  return data;
}

export async function deactivateEmployee(api, id) {
  const { data } = await api.patch(`/employee/${id}/deactivate`);
  return data;
}

export async function reactivateEmployee(api, id) {
  const { data } = await api.patch(`/employee/${id}/reactivate`);
  return data;
}
export async function updateSchedule(api, payload) {
  const { data } = await api.patch(`/account/schedule`, payload);
  return data;
}

export async function getAccount(api) {
  const { data } = await api.get(`/account`);
  return data;
}

export async function getAreas(api) {
  const { data } = await api.get(`/areas`);
  return data;
}
//change status to deleted, not actually delete in database
export async function deleteCustomArea(api, id) {
  const { data } = await api.patch(`/area/custom/${id}/delete`);
  return data;
}
export async function createCustomArea(payload) {
  const { data } = await lightsApiPrivate.post(`/area/custom`, payload);
  return data;
}

export async function getQuestions(api, id) {
  const { data } = await api.get(`/questions/area/${id}`);
  return data;
}
export async function createCustomQuestion(payload) {
  const { data } = await lightsApiPrivate.post(`/question/custom`, payload);

  return data;
}

export async function patchQuestionCommentsRead(api, id) {
  const { data } = await api.patch(`/question/read/${id}`);
  return data;
}

export async function deleteCustomQuestion(api, id) {
  const { data } = await api.patch(`/question/custom/${id}/delete`);
  return data;
}

export async function getActions(api, filter) {
  const { data } = await api.get(`/actions`, { params: filter });
  return data;
}

export async function getDiversityOverview(api) {
  const { data } = await api.get(`/diversity/overview`);
  return data;
}
export async function getDiversityScoresForAreas(api, selectedDimension) {
  const { data } = await api.get(`/scores/contrast/areas`, {
    params: { selectedDimension },
  });
  return data;
}
export async function getDiversityScoresForQuestions(
  api,
  selectedArea,
  selectedDimension
) {
  const { data } = await api.get(
    `/scores/contrast/area/${selectedArea}/questions`,
    {
      params: {
        selectedDimension,
      },
    }
  );
  return data;
}

export async function postEmployeeInvite(api, id) {
  const { data } = await api.post(`/employee/invite/${id}`);
  return data;
}

export async function postEmployeesInvite(api) {
  const { data } = await api.post(`/employees/invite`);
  return data;
}

export async function postSelectedEmployeeInvite(api, employeeIds) {
  const { data } = await api.post(`/employees/invite/${employeeIds}`);
  return data;
}

export async function postEmployeeReminder(api, id) {
  const { data } = await api.post(`/employee/reminder/${id}`);
  return data;
}

export async function postEmployeesReminder(api, employeeIds) {
  const { data } = await api.post(`/employees/reminder/${employeeIds}`);
  return data;
}

export async function postChatMessage(api, payload) {
  const { data } = await api.post(`/chatMessage`, payload);
  return data;
}

export async function postDialog(api) {
  const { data } = await api.post(`/dialog`);
  return data;
}

export async function getAdminDialogs(api) {
  const { data } = await api.get(`/dialogs/admin`);
  return data;
}

export async function getSurveeDialogs(api) {
  const { data } = await api.get(`/dialogs/survee`);
  return data;
}

export async function patchDialogRead(api, id) {
  const { data } = await api.patch(`/dialog/${id}/read`);
  return data;
}

export async function getChatMessages(api, id) {
  const { data } = await api.get(`/dialog/${id}/chatmessages`);
  return data;
}

export async function sendActionRequest(api, id) {
  const { data } = await api.post(`/action/support-request/${id}`);
  return data;
}

export async function getAnswerFormula(api) {
  const { data } = await api.get(`/answer/formula`);
  return data;
}

export async function patchAccountPrefix(api, payload) {
  const { data } = await api.patch(`/account/prefix`, payload);
  return data;
}

export async function getEngagementFact() {
  const { data } = await lightsApi.get(`/dei-fact`);
  return data;
}

export async function patchIsMailMinuteRandom(api, payload) {
  const { data } = await api.patch(`/account/minute-random`, payload);
  return data;
}
// TODO split up in multiple files
