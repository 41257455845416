import { AreaNameBold, ButtonContainer, Container, ModalTitle } from "./styles";

import Button from "@mui/material/Button";
import Modal from "react-modal";
import React from "react";
import { getModalStyle } from "../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";
import { useNavigate } from "react-router-dom";

export const ConfirmDeleteArea = ({
  areaName,
  areaId,
  isModalOpen,
  closeModal,
}) => {
  const navigate = useNavigate();
  const { deleteCustomArea } = useLightsApiPrivate();
  const alert = useAlert();

  const handleDelete = async () => {
    try {
      await deleteCustomArea(areaId);
      closeModal();
      alert.success("Bereich " + areaName + " erfolgreich gelöscht!");
      navigate("/areas");
    } catch (err) {
      alert.error("Fehler beim Löschen des Bereichs!");
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={getModalStyle()}
      contentLabel="Area Deletion Modal"
    >
      <Container>
        <ModalTitle>
          Bist Du sicher, dass Du den Bereich{" "}
          <AreaNameBold>{areaName}</AreaNameBold> und alle darin enthaltenen
          Fragen unwiderruflich löschen willst?
        </ModalTitle>
        <ButtonContainer>
          <Button onClick={closeModal} variant="neutral">
            Abbrechen
          </Button>
          <Button onClick={handleDelete} variant="warning">
            Löschen
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
