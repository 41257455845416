import styled from "@emotion/styled";

export const RightSideContainer = styled("div")`
  display: grid;
  grid-auto-rows: min-content;
  align-items: start;
  justify-items: start;
`;

export const RowContainer = styled("div")`
  display: grid;
  gap: 5px;
  grid-template-columns: 84px auto;
  align-items: start;
`;

export const MailSplitSwitchContainer = styled("div")`
  display: grid;
  gap: 5px;
  grid-template-columns: 84px auto;
  align-items: center;
`;

export const MailSplitSection = styled("div")`
  margin-top: 10px;
`;
