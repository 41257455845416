import ChartContainer, { ChartContainerProps } from "./ChartContainer";
import { useEffect, useState } from "react";

import AreaSection from "../common/AreaSection";
import { AreaSectionHeader } from "../common/AreaSectionHeader";
import Heading1 from "../common/Heading1";
import LoadingAnimation from "../common/LoadingAnimation";
import colors from "../common/colors";
import useLightsApiPrivate from "../../hooks/useLightsApiPrivate";

const DiversityPage = () => {
  const { getDiversityOverview } = useLightsApiPrivate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ChartContainerProps | null>(null);

  const fetchData = async () => {
    await getDiversityOverview().then((response) => {
      setData(response);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>Diversity</Heading1>
      </AreaSectionHeader>
      <AreaSection
        content={sectionContent.DiversityOverviewSection}
        backLinkTo={undefined}
        isCustom={false}
        areaName={undefined}
        areaId={undefined}
        updateQuestionList={undefined}
      >
        <ChartContainer {...(data ?? ({} as ChartContainerProps))} />
      </AreaSection>
    </>
  );
};

const sectionContent = {
  DiversityOverviewSection: {
    id: "teams",
    title: "Überblick der Diversitätsdimensionen",
    infoText:
      "Klicke auf eine Dimension, um ein Balkendiagramm mit der prozentualen Verteilung der Diversitätsoptionen anzuzeigen.",
  },
};

export default DiversityPage;
