import { useEffect, useState } from "react";
import colors from "./common/colors";

const IEWarning = ({ children }) => {
  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isInternetExplorer =
      userAgent.includes("MSIE") || userAgent.includes("Trident/");

    if (isInternetExplorer) {
      setIsIE(true);
    }
  }, []);

  if (!isIE) return children;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.midnight,
        color: "white",
        padding: "10px",
        textAlign: "center",
        height: "100vh",
      }}
    >
      ⚠️ Sie benutzen den Internet Explorer, der nicht unterstützt wird. Bitte
      verwenden Sie einen modernen Browser wie Chrome, Firefox oder Edge.
    </div>
  );
};

export default IEWarning;
