import Dropdown from "../../../../../../common/Dropdown";
import colors from "../../../../../../common/colors";

interface TimeDropdownProps {
  day: number;
  hour: number;
  updateHour: (weekDay: number, newHour: number) => void;
  isActive: boolean | undefined;
}

const undefinedCheck = (value: boolean | undefined) => {
  return value ? value : false;
};

export const TimeDropdown = (props: TimeDropdownProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.updateHour(props.day, parseInt(event.target.value));
  };

  return (
    <Dropdown
      key={props.day}
      menuItems={menuItems}
      label={undefined}
      multiple={false}
      value={props.hour}
      onChange={handleSelectChange}
      disabled={!undefinedCheck(props.isActive)}
      size={"small"}
      sx={{
        backgroundColor: undefinedCheck(props.isActive)
          ? colors.white
          : colors.darkGrey,
        height: "25px",
        borderRadius: "5px",
      }}
    />
  );
};

const menuItems = Array.from({ length: 24 }, (_, i) => ({
  id: i + 1,
  label: `${i + 1}:00 Uhr`,
}));
