import React, { useEffect, useRef, useState } from "react";

import ActionList from "./ActionList";
import AreaSection from "../../common/AreaSection";
import { AreaSectionHeader } from "../../common/AreaSectionHeader.js";
import { DeleteCustomArea } from "./DeleteCustomArea";
import Heading1 from "../../common/Heading1";
import LoadingAnimation from "../../common/LoadingAnimation";
import MainScoreSL from "../../report/MainScoreSL";
import { QuestionList } from "./QuestionList.js";
import colors from "../../common/colors";
import useAuth from "../../../hooks/useAuth.js";
import useLightsApiPrivate from "../../../hooks/useLightsApiPrivate";
import { useParams } from "react-router-dom";

const AreaReportPage = () => {
  const { id } = useParams();
  const {
    getScores,
    getScoreOverTime,
    getQuestions,
    getAreas,
    getActions,
    getEmployees,
  } = useLightsApiPrivate();

  const [scoreTimeline, setScoreTimeline] = useState(null);
  const [score, setScore] = useState(null);
  const [area, setArea] = useState(null);
  const [actions, setActions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  const actionsRef = useRef();
  const areaName = score ? score.areaName : "";
  const isCustomArea = area ? area.isCustom : false;

  const { auth } = useAuth();

  const showActions =
    auth.id === "63513eaae0eb48894da41081" ||
    auth.id === "652c00fd3ae684647b959f66";

  useEffect(() => {
    const fetchScores = async () => {
      const shouldScrollToActions = window.location.href.includes("actions");

      setLoading(false);
      const allScores = await getScores();
      const scoreOverTime = await getScoreOverTime(id);
      const questions = await getQuestions(id);
      const areas = await getAreas();
      const actions = await getActions({ areaId: id });

      const curArea = areas.find((area) => area._id === id);
      const currentScore = allScores.find((score) => score.areaId === id);

      const employees = await getEmployees();

      setEmployees(employees);
      setArea(curArea);
      setQuestions(questions);
      setScoreTimeline(scoreOverTime);
      setActions(actions);

      setScore({
        ...scoreOverTime[scoreOverTime.length - 1],
        areaName: currentScore.areaName,
        areaIdentifier: currentScore.areaIdentifier,
      });

      if (shouldScrollToActions && actionsRef.current) {
        actionsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (loading) {
      fetchScores().catch(console.error);
    }
  });

  const updateQuestionList = async () => {
    const questions = await getQuestions(id);
    setQuestions(questions);
  };

  // TODO dynamische Lösung hierfür finden
  const sectionContent = {
    MainScoreSection: {
      id: "timeline",
      title: `Dein Score im Bereich "${areaName}"`,
      infoText: `Hier wird widergespiegelt, wie Eure Belegschaft den ${
        isCustomArea ? "Custom" : ""
      } Bereich ${areaName} wahrnimmt.`,
    },
    QuestionSection: {
      id: "question",
      title: "Antworten aller Mitarbeiter*innen",
      infoText:
        "Hier erhältst Du einen Überblick über alle Fragen, die bislang beantwortet wurden.",
    },
    ActionSection: {
      id: "action",
      title: `Maßnahmen im Bereich ${areaName}`,
      infoText: `Hier erhältst Du einen Überblick über potenzielle Maßnahmen zur Steigerung des Bereichsscores.`,
    },
    DeleteSection: {
      id: "delete",
      title: "Bereich löschen",
      infoText:
        "Achtung! Dieser Bereich und alle zugehörigen Fragen werden unwiderruflich gelöscht.",
    },
  };

  if (!score) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <AreaSectionHeader>
        <Heading1 fontColor={colors.natural}>{`${areaName} Report`}</Heading1>
      </AreaSectionHeader>
      <AreaSection content={sectionContent.MainScoreSection}>
        <MainScoreSL
          score={score}
          scoreTimeline={scoreTimeline}
          area={area}
          employees={employees}
        ></MainScoreSL>
      </AreaSection>
      <AreaSection
        content={sectionContent.QuestionSection}
        isCustom={area.isCustom}
        areaName={areaName}
        areaId={id}
        updateQuestionList={updateQuestionList}
      >
        <QuestionList
          questions={questions}
          areaName={areaName}
          areaIdentifier={score.areaIdentifier}
          isCustom={area.isCustom}
          updateQuestionList={updateQuestionList}
        />
      </AreaSection>
      {showActions && (
        <AreaSection content={sectionContent.ActionSection}>
          <ActionList actions={actions} actionsRef={actionsRef} />
        </AreaSection>
      )}
      {area.isCustom && (
        <AreaSection content={sectionContent.DeleteSection}>
          <DeleteCustomArea areaId={area._id} areaName={areaName} />
        </AreaSection>
      )}
    </>
  );
};

export default React.memo(AreaReportPage);
