import { ReactComponent as AddIconSvg } from "../../../../../icons/addIcon.svg";
import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const ItemContainerAddCustom = styled("div")`
  border: 1px solid ${colors.lavender};
  padding: 20px;
  font-size: 30px;
  height: 90px;
  width: 200px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
`;

export const AddCustomTitle = styled("p")`
  color: ${colors.midnight};
  margin-top: -2px;
  font-size: 16px;
  font-weight: 500;
`;

export const AddIcon = styled(AddIconSvg)`
  font-size: 40px;
  margin-top: 7px;
  margin-bottom: 5px;
`;
