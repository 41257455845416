import {
  ACTIVE_STATUS,
  CREATED_STATUS,
  DEACTIVATED_STATUS,
  INVITED_STATUS,
} from "../../../common/StatusChip/statusOptions";

export const statusLabels = {
  created: CREATED_STATUS,
  invited: INVITED_STATUS,
  active: ACTIVE_STATUS,
  deactivated: DEACTIVATED_STATUS,
};
