import {
  LeftQuestionCard,
  NoQuestionText,
  QuestionGrid,
  QuestionGridContainer,
  RightQuestionCard,
} from "./styles";

import QuestionCard from "./QuestionCard";
import React from "react";

export const QuestionList = ({
  questions,
  areaName,
  areaIdentifier,
  isCustom,
  updateQuestionList,
}) => {
  return (
    <QuestionGridContainer>
      {questions.length === 0 ? (
        <NoQuestionText>
          Dieser Bereich hat noch keine Fragen. Bitte füge Deine erste Frage
          hinzu.
        </NoQuestionText>
      ) : (
        <QuestionGrid>
          {questions.map((question, index) => {
            if (index % 2 === 0) {
              return (
                <LeftQuestionCard key={index}>
                  <QuestionCard
                    index={index}
                    question={question}
                    areaName={areaName}
                    areaIdentifier={areaIdentifier}
                    isCustom={isCustom}
                    updateQuestionList={updateQuestionList}
                  />
                </LeftQuestionCard>
              );
            } else {
              return (
                <RightQuestionCard key={index}>
                  <QuestionCard
                    index={index}
                    question={question}
                    areaName={areaName}
                    areaIdentifier={areaIdentifier}
                    isCustom={isCustom}
                    updateQuestionList={updateQuestionList}
                  />
                </RightQuestionCard>
              );
            }
          })}
        </QuestionGrid>
      )}
    </QuestionGridContainer>
  );
};
