import { AddCustomTitle, AddIcon, ItemContainerAddCustom } from "./styles";

import AddCustomAreaForm from "./AddCustomAreaForm";
import React from "react";
import { useState } from "react";

const AddNewCustomArea = ({ updateAreaList }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ItemContainerAddCustom onClick={handleOpen}>
        <AddIcon />
        <AddCustomTitle>Hinzufügen</AddCustomTitle>
      </ItemContainerAddCustom>
      <AddCustomAreaForm
        updateAreaList={updateAreaList}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default React.memo(AddNewCustomArea);
