import React, { createRef, useEffect, useState } from "react";

import ChartNavigationBar from "./ChartNavigationBar";
import { Container } from "./styles";
import DiversityBarChart from "./DiversityBarChart/index";
import InactiveChart from "./InactiveChart";

export interface ChartContainerProps {
  data?: {
    dimensionData: Record<string, { name: string; perc: number }[]>;
    employeeCount: number;
    date: string;
  };
}

const ChartContainer = (props: ChartContainerProps) => {
  const dimensionKeys = Object.keys(props.data?.dimensionData || {});

  const [currentKey, setCurrentKey] = useState<string>(dimensionKeys[0]);

  const refs = dimensionKeys.reduce((acc, key) => {
    acc[key] = createRef<HTMLDivElement | null>();
    return acc;
  }, {} as Record<string, React.RefObject<HTMLDivElement | null>>);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.innerHeight * 0.3;
      dimensionKeys.forEach((key) => {
        const ref = refs[key].current;
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (rect.top <= offset && rect.bottom >= offset) {
            setCurrentKey(key);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dimensionKeys, refs]);

  return (
    <Container>
      <ChartNavigationBar
        refs={refs as Record<string, React.RefObject<HTMLDivElement>>}
        dimensionKeys={dimensionKeys}
        currentKey={currentKey}
      />
      {props.data !== undefined ? (
        dimensionKeys.map((key) => (
          <>
            <DiversityBarChart
              // @ts-ignore will not be undefined unless the backend sends malformed data because of the nullcheck above
              data={props.data.dimensionData[key]}
              dimension={key}
              //@ts-ignore
              employeeCount={props.data.employeeCount}
              //@ts-ignore
              date={props.data.date}
              ref={refs[key]}
              references={
                refs as Record<string, React.RefObject<HTMLDivElement>>
              }
              dimensionKeys={dimensionKeys}
            />
          </>
        ))
      ) : (
        <InactiveChart />
      )}
    </Container>
  );
};

export default ChartContainer;
