import { Button, TextField } from "@mui/material";

import { CloseRounded } from "@mui/icons-material";
import colors from "../../colors";
import styled from "@emotion/styled";

export const CloseIcon = styled(CloseRounded)`
  cursor: pointer;
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 20px;
  float: right;
`;

export const FormTextFieldQuestion = styled(TextField)`
  width: 445px;
  height: 110px;
  margin-left: 30px;
  margin-top: 0px;
`;

export const FormContainer = styled("div")`
  position: relative;
  top: 50%;
  left: 50%;
  padding-bottom: 20px;
  transform: translate(-50%, -50%);
  width: 510px;
  height: ${({ error }) => (error ? "283px" : "300px")};
  background-color: white;
  border-radius: 5px;
  border: 2px solid ${colors.grey};
  z-index: 1;
`;

export const FormTitle = styled("h3")`
  font-weight: 500;
  margin-top: 25px;
  font-size: 20px;
  margin-left: 30px;
  font-family: Rubik;
`;

export const ButtonContainer = styled(Button)`
  display: flex;
  justify-content: center;
  width: 130px;
  background-color: ${colors.lavender};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ error }) => (error ? "-5px" : "0px")};
`;

export const ErrorText = styled("p")`
  color: ${colors.red};
  margin-left: 32px;
  margin-top: 12px;
  font-size: 13px;
`;

export const OverlayContainer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ReviewInfoText = styled("div")`
  margin-top: 12px;
  font-size: 12px;
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  margin-right: 30px;
  font-size: 12px;
  line-height: 14px;
  margin-left: 32px;
  margin-bottom: 12px;
`;
