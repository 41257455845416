import styled from "@emotion/styled";

export const ModalTitle = styled("p")`
  margin-bottom: 10px;
  width: 100%;
  font-family: "Rubik";
  font-weight: 400;
  padding: 0;
  text-align: left;
  margin-top: 5px;
  font-size: 16px;
`;

export const ButtonContainer = styled("div")`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const QuestionContent = styled("div")`
  font-size: 16px;
  width: 600px;
  text-align: left;
  max-width: 550px;
  word-wrap: break-word;
`;
