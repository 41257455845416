import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  ChartContainer,
  ChartHeading,
  ChartHeadingRightWrapper,
  LavenderSpan,
  ResponsiveContainerWrapper,
} from "./styles";
import React, { Ref, forwardRef } from "react";

import { CustomXAxisTick } from "./CustomXAxisTick";
import Heading2 from "../../../common/Heading2";
import { diversityContent } from "../../../../diversityContent";
import { getGermanFormat } from "../../../../utils/date";

interface DiversityBarChartProps {
  data: {
    name: string;
    perc: number;
  }[];
  dimension: string;
  employeeCount: number;
  date: string;
  references: Record<string, React.RefObject<HTMLDivElement>>;
  dimensionKeys: string[];
}

const DiversityBarChart = forwardRef<HTMLDivElement, DiversityBarChartProps>(
  (props: DiversityBarChartProps, ref: Ref<HTMLDivElement>) => {
    props.data.sort((a, b) => b.perc - a.perc);

    const getHeading = (key: keyof typeof diversityContent) => {
      if (key === "religion") return "Religion & Weltanschauung";
      return diversityContent[key];
    };

    return (
      <ChartContainer>
        <ChartHeading>
          <Heading2>
            {getHeading(props.dimension as keyof typeof diversityContent)}
          </Heading2>
          <ChartHeadingRightWrapper>
            <Heading2>
              Stand:{" "}
              {getGermanFormat(new Date(props.date), { year: "numeric" })}{" "}
              |&nbsp;
            </Heading2>
            <Heading2>
              <LavenderSpan>{props.employeeCount}</LavenderSpan>
              &nbsp;Personen
            </Heading2>
          </ChartHeadingRightWrapper>
        </ChartHeading>
        <ResponsiveContainerWrapper ref={ref}>
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart data={props.data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                height={120}
                tick={<CustomXAxisTick />}
                interval={0}
              />
              <YAxis
                tickCount={11}
                domain={[0, 100]}
                style={{ fontSize: 14 }}
              />
              <Bar dataKey="perc" fill="#8884d8" radius={[10, 10, 0, 0]}>
                <LabelList
                  dataKey="perc"
                  position="top"
                  style={{ fill: "black", fontSize: 14 }}
                  formatter={(value: number) => `${value}%`}
                />
                {props.data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.name === "not_applicable" ? "#898989" : "#8884d8"
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ResponsiveContainerWrapper>
      </ChartContainer>
    );
  }
);

export default DiversityBarChart;
